import Api from 'utils/Api'
import axios from 'axios'

export function getProductsList(filter_str) {
    return Api
        .get(`product/furnish-products/${filter_str ? filter_str : ''}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getProduct(product_id) {
    return Api
        .get(`product/products/${product_id}/`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getAlternateProducts(scene_id, x_loc, y_loc) {
    return Api
        .get(`product/products/product_alternative_by_loc/?scene_id=${scene_id}&x_loc=${x_loc}&y_loc=${y_loc}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getCollectionProducts(theme_id) {
    let url
    if (theme_id) {
        url = `product/get-collection/?theme_id=${theme_id}`
    } else {
        url = `product/get-collection/`
    }
    return Api
        .get(url)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getProductsCategories() {
    return Api
        .get('PLabelling/category/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getProductDimensionOptions() {
    return Api
        .get('Scraper/productData/get_dimension_options/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getFilter(obj) {
    let url_params
    if (obj.source == "RelatedProduct") {
        url_params = `${obj.source}&product_id=${obj.product_id}`
    } else {
        url_params = `${obj.source}`
    }
    return Api
        .get(`/product/furnish-products/filtering/?source=${url_params}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
//for product list page
export function getFilters(obj) {
  
    return Api
        .get(`/product/search_products/get_filter_lists/?product_id=${obj}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function getAllProducts(obj) {

    return Api
        .post(`/product/search_products/search/`,obj)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getRelatedProduct(filter_str, config) {
    return Api
        .get(`product/related-products${filter_str ? filter_str : ''}`, config)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

//only v3
export function getSearchedProducts(obj) {
    return Api
        .get(`v3/CADVisualization/ehd/search_products/?scene_id=${obj.scene}&query=${obj.query}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

//only v3
export function customise_scene(obj) {
    return Api.post(
        `v3/CADVisualization/customization/customize_scene/`,
        obj,
    ).then(response => {
        return response.data
    })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function removeProduct(obj) {
    return Api.post(
        `CADVisualization/scene/remove_product/`,
        obj,
    ).then(response => {
        return response.data
    })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
// export function removeProduct(obj) {
//     return Api.post(
//         `v3/CADVisualization/scene/remove_product/`,
//         obj,
//     ).then(response => {
//         return response.data
//     })
//         .catch(e => {
//             return { status: e.response.status, data: e.response.data }
//         })
// }

export function changeProduct(obj, config) {
    return Api.post(
        `CADVisualization/customization/change_product/`,
        obj,
        config
    ).then(response => {
        return response
    })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
// export function changeProduct(obj, config) {
//     return Api.post(
//         `v3/CADVisualization/customization/change_product/`,
//         obj,
//         config
//     ).then(response => {
//         return response
//     })
//         .catch(e => {
//             return { status: e.response.status, data: e.response.data }
//         })
// }

export function toggleService(obj) {
    return Api.post(
        `CADVisualization/customization/toggle_service/`,
        obj,
    ).then(response => {
        return response
    })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
// export function toggleService(obj, config) {
//     return Api.post(
//         `v3/CADVisualization/customization/toggle_service/`,
//         obj,
//         config
//     ).then(response => {
//         return response
//     })
//         .catch(e => {
//             return { status: e.response.status, data: e.response.data }
//         })
// }

export function toggleProduct(obj, config) {
    return Api.post(
        `CADVisualization/customization/toggle_product/`,
        obj,
        config
    ).then(response => {
        return response
    })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
// export function toggleProduct(obj, config) {
//     return Api.post(
//         `v3/CADVisualization/customization/toggle_product/`,
//         obj,
//         config
//     ).then(response => {
//         return response
//     })
//         .catch(e => {
//             return { status: e.response.status, data: e.response.data }
//         })
// }

export function getProductsListByTheme(theme_id) {
    return Api
        .get(`product/products/products_for_theme/?theme_id=${theme_id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getProducts(page) {
    return Api
        .get(`product/products/?page=${page}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function addProduct(obj) {
    return Api
        .post(`CADVisualization/customization/add_new_product/`, obj)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getProductsBySearch(obj) {
    return Api
        .get(`product/furnish-products/?search=${obj.string}&page=${obj.page}&size=8`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}